<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-row class="mt-3">
      <v-col class="py-0" cols="12" md="6">
        <v-card class="pa-0">
          <v-card-title>Estilos generales</v-card-title>
          <v-card-text>
            <v-switch
              v-model="cambiarColorFondo"
              label="Personalizar color de fondo de inicio de sesión"
              class="ma-0"
            ></v-switch>
            <v-text-field
              v-model="color"
              hide-details
              class="ma-0 pa-0"
              v-if="cambiarColorFondo"
              solo
            >
              <template v-slot:append>
                <v-menu
                  v-model="menu"
                  nudge-bottom="105"
                  nudge-left="16"
                  max-width="500px"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <div :style="swatchStyle" v-on="on" />
                  </template>
                  <v-card class="pt-0">
                    <v-card-text class="ma-0 pt-0 ">
                      <v-row class="px-3 py-0">
                        <v-col
                          cols="4"
                          class="textEstandar grey--text caption py-0 px-1 align-self-center"
                        >
                          <!-- <div class="grey--text ma-1 fs-5"> -->
                          <label>Color estándar gecros</label>
                          <!-- </div> -->
                        </v-col>
                        <v-col
                          cols="3"
                          class="grey--text caption py-0 px-1 align-self-center"
                        >
                          <!-- <div class="grey--text  ma-1"> -->
                          <label>#195472</label>
                          <!-- </div> -->
                        </v-col>

                        <v-col cols="2">
                          <div class="color py-0 align-self-auto">
                            <v-input
                              backgroundColor="#195472"
                              height="20px"
                              width="20px"
                            ></v-input>
                          </div>
                        </v-col>
                        <v-tooltip top>
                          <span>{{ textCopied }}</span>
                          <template v-slot:activator="{ on, attrs }">
                            <v-col cols="3 align-self-auto">
                              <v-icon
                                @click="
                                  (color = '#195472'), (textCopied = 'Copiado')
                                "
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ copyIcon }}
                              </v-icon>
                            </v-col>
                          </template>
                        </v-tooltip>
                      </v-row>
                      <v-color-picker v-model="color" flat />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </v-card-text>

          <v-col class="py-0">
            <label class="grey--text caption"
              >Seleccione la imagen que desee como logo de fondo de pantalla de
              inicio de sesión</label
            >
          </v-col>
          <v-col align="right" class="py-0">
            <v-btn
              raised
              class="grey lighten-2"
              @click="seleccionarArchivo"
              small
            >
              Adjuntar imagen</v-btn
            ></v-col
          >
          <v-row>
            <input
              type="file"
              style="display:none"
              ref="fileNone"
              accept="image/*"
              :src="image"
              @change="onFilePicked"
            />
          </v-row>
          <v-col cols="12" md="6" offset-sm="3">
            <img :src="imageUrl" width="250" />
          </v-col>
          <v-col align="right" v-if="imageUrl">
            <v-btn depressed raised @click="quitarImagen" color="error" x-small>
              Quitar imagen</v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="reestablecerConfigPredet"
              label="Reestablecer configuración predeterminada"
              class="ma-0"
            ></v-switch>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-content-end pa-0 mr-5">
      <v-btn
        @click.prevent="apply"
        color="primary"
        :loading="loading"
        :disabled="loading"
      >
        Actualizar
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "PantallaLoginAdministracion",
  components: { PageHeader, GoBackBtn },
  data() {
    return {
      title: enums.titles.PANTALLA_LOGIN_ADMINISTRACION_SISTEMA,
      copyIcon: enums.icons.COPY,
      cambiarColorFondo: false,
      loading: false,
      color: null,
      menu: false,
      routeToGo: "ConfiguracionAdmSistema",
      imageUrl: "",
      image: null,
      textCopied: "Copiar",
      files: null,
      reestablecerConfigPredet: false,
      sameImage: true,
      logoFromDataBase: ""
    };
  },

  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },

  async mounted() {
    this.updateParams();
    this.getLogo();
  },
  watch: {
    menu() {
      if (!this.menu) {
        this.textCopied;
      }
      setInterval(() => {
        this.textCopied = "Copiar";
      }, 2000);
    },

    reestablecerConfigPredet() {
      if (this.reestablecerConfigPredet) {
        this.quitarImagen();
        this.cambiarColorFondo = false;
      } else {
        this.cambiarColorFondo = true;
        this.getLogo();
      }
    },
    cambiarColorFondo() {
      if (this.cambiarColorFondo) this.reestablecerConfigPredet = false;
    }
  },
  computed: {
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    },
    hexColorCopied() {
      return (this.color = "#195472");
    }
  },

  methods: {
    ...mapActions({
      setConfigAvanzada: "AdministracionSistema/setConfigAvanzada",
      getConfigAvanzada: "AdministracionSistema/getConfigAvanzada",
      updateConfigPredet: "AdministracionSistema/updateConfigPredet",
      getCustomLogo: "AdministracionSistema/getCustomLogo",
      saveCustomLogo: "AdministracionSistema/saveCustomLogo",
      setAlert: "user/setAlert"
    }),
    async getLogo() {
      const response = await this.getCustomLogo();
      if (response.data != "") {
        this.imageUrl = response.data;
        this.logoFromDataBase = response.data;
      }
    },
    seleccionarArchivo() {
      this.$refs.fileNone.click();
    },
    quitarImagen() {
      this.imageUrl = "";
      this.files = null;
      this.image = null;
    },
    onFilePicked(event) {
      const files = event.target.files; // este evento se activa al cambiar la entrada, para recuperar el archivo adjunto
      this.files = files[0];
      let filename = files[0].name; // recupera el nombre del archivo
      if (filename.lastIndexOf(".") <= 0) {
        //verifica si el nombre del archivo tiene "." en el último índice
        this.setAlert({
          type: "warning",
          message: "Por favor ingrese una imagen válida"
        });
      }
      const fileReader = new FileReader(); // convierto en string para que el lector de archivo de js pueda leerlo
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
    },

    async apply() {
      this.loading = true;
      if (this.reestablecerConfigPredet) {
        await this.updateConfigPredet();
        this.setAlert({
          type: "success",
          message: "Guardado con éxito."
        });
        this.updateParams();
        this.loading = false;
      } else {
        // guardar imagen
        if (this.logoFromDataBase == this.imageUrl) this.sameImage = true;
        else this.sameImage = false;
        let formData = new FormData();
        formData.append("file", this.files);
        const responseSameImage = await this.saveCustomLogo({
          sameImage: this.sameImage,
          data: formData
        });
        // guardar color
        let data = {
          hexColor: this.cambiarColorFondo ? this.color : null
        };
        const responseColor = await this.setConfigAvanzada(data);
        if (responseColor.status === 200 && responseSameImage.status === 200) {
          this.setAlert({
            type: "success",
            message: "Guardado con éxito."
          });
          this.updateParams();
          this.loading = false;
        }
      }
    },
    async updateParams() {
      const res = await this.getConfigAvanzada();
      if (res.hexColor) {
        this.cambiarColorFondo = true;
        this.color = res.hexColor;
      } else {
        this.color = "#195472";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .v-menu__content .theme--light .menuable__content__active {
  margin-top: 5rem !important;
}

.textEstandar {
  font-size: 12px;
}
.iconColor {
  border-radius: 100%;
  width: 50;
}
</style>
